import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'home.vue').default },
      { path: '/productos', component: require(page+'products/results.vue').default, meta:{ title:"Productos" }},
      { path: '/productos/:id?', component: require(page+'products/results.vue').default, meta:{ title:"Productos" }},
      { path: '/producto/:id', component: require(page+'products/detail.vue').default, meta:{ title:"Producto" }},
      { path: '/nosotros', component: require(page+'about-us/index.vue').default, meta:{ title:"Nosotros" }},
	    { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{ title:"Contacto" }},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "MrBag", filter: (title)=>{ return title+" - MrBag"; } }
);

// export {routes};
export default MyRouter;
