<template lang="html">
  <b-form id="products-results-page" @submit="onSubmit">

    <section class="products-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 box-breadcrumb-s1">
            <span>Inicio</span>
            <router-link to="">Productos</router-link>
          </div>
        </div>

        <div class="row">
          <!-- Col products -->
          <div class="col-lg col-results">
            <div class="row mb-3">
              <div class="col-12 col-lg-9 col-title">
                <h2 class="title-s1">Nuestros productos</h2>

                <p class="info">
                  Realizamos productos de carácter personalizado con especificaciones adecuadas a la necesidad de cada cliente, en cuestión de: diseño, material, volúmenes y tiempos de entrega.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 col-lg-4 box-product-sample-s1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
                <router-link class="box-link" :to="'/producto/'+p.id">
                  <!-- <span class="bubble">Nuevo</span> -->
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                    <img src="public/images/shared/empty-1.png">
                  </div>

                  <div class="box-descr">
                    <h6 class="name">{{ p.name }}</h6>

                    <div class="descr">
                      {{ p.short_description }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <!-- <div class="row mt-4" v-if="pagination.total_products > 6">
              <div class="col-12 text-center">
                <div class="d-inline-block mx-0 col-pagination-sample-1">
                  <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                </div>
              </div>
            </div> -->
          </div>
          <!--  -->

        </div>
      </div>
    </section>

  </b-form>
</template>

<script>
export default {
  data() {
    return {
      products: [],

      pagination:{
        currentpage: 1,
        total: 1,
        total_products: 16,
        number: 10
      },
      id:null,

    }
  },
    watch:{
        '$route.params.id':function(){
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getProducts();
        }
    },
    methods: {
      onSubmit(event) {
            event.preventDefault();
        },
        getProducts: function(){
            axios.get(tools.url('/api/products/'+this.id)).then((response)=>{
                this.products = response.data;
            });
        },

    },

    mounted() {
        this.id = this.$root._getURLID(this.$route.params.id);
        this.getProducts();
    }
}
</script>
