<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.pc" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.movil" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="video-section">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12 col-title">
            <h2 class="title">Instalaciones</h2>
          </div> -->

          <div class="col-12 col-video">
            <video controls>
               <source src="public/videos/video.mp4" type="video/mp4">
              </video>
          </div>
        </div>
      </div>
    </section>

    <section class="values-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-title">
            <h2 class="title">Nuestros valores</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-md-3 col-value">
            <div class="box-icon">
              <div class="circle">
                <span><img src="public/images/pages/home/icon-1.png"></span>
              </div>
            </div>

            <h5 class="title">CALIDAD</h5>
          </div>

          <div class="col-6 col-md-3 col-value">
            <div class="box-icon">
              <div class="circle">
                <span><img src="public/images/pages/home/icon-2.png"></span>
              </div>
            </div>

            <h5 class="title">DURABILIDAD</h5>
          </div>

          <div class="col-6 col-md-3 col-value">
            <div class="box-icon">
              <div class="circle">
                <span><img src="public/images/pages/home/icon-3.png"></span>
              </div>
            </div>

            <h5 class="title">PUNTUALIDAD</h5>
          </div>

          <div class="col-6 col-md-3 col-value">
            <div class="box-icon">
              <div class="circle">
                <span><img src="public/images/pages/home/icon-4.png"></span>
              </div>
            </div>

            <h5 class="title">INNOVACIÓN</h5>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="about-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-image">
            <img src="public/images/pages/home/about.png">
          </div>

          <div class="col-lg-7 col-text">
            <h2 class="title">¿Quiénes somos?</h2>
            <p>
              Somos una empresa sólida dedicada a la fabricación de empaque flexible impreso que cuenta con cimientos basados en nuestros valores de operación y en la seguridad de los procesos que llevamos a cabo, ya que gracias a estos hemos mantenido un crecimiento e innovación constante de nuestros productos. Más de 5 años nos respaldan.
            </p>

            <h2 class="mt-3 mt-lg-5 title">Nuestro compromiso</h2>
            <p>
              Satisfacer los requerimientos de nuestros clientes priorizando sus necesidades y dando soluciones innovadoras y oportunas en cada una de sus solicitudes.
            </p>
          </div>
        </div>
      </div>
    </section> -->

    <CustomersComponent></CustomersComponent>

  </div>
</template>

<script>
import CustomersComponent from './about-us/customers-component.vue';
export default {
  components: {
    CustomersComponent
  },

  data() {
    return {
      banners: {
        pc:[],
        movil:[],
      },


      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      // == ==
    }
  },
  methods: {
    getHome(){
      axios.get(tools.url("/api/home")).then((response)=>{
          this.banners = response.data.banners;
         
        }).catch((error)=>{
          console.error('Error API Home');
        });
    },
  },
  mounted(){
      this.getHome();
  },
}
</script>
