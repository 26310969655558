<template lang="html">
  <section class="our-customers-section">
    <div class="container ">
      <h2 class="title">Orgullosamente proveedores de</h2>

      <div class="box-carousel">
        <swiper class="swiper" :options="customersOptions">
          <swiper-slide v-for="(c, cInx) in customers" :key="'cInx-'+cInx">
            <div class="box-brand">
              <div class="image">
                <img :src="c.imgURL">
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      customers: [
        { imgURL: 'public/images/pages/about-us/customer-1.svg' },
        { imgURL: 'public/images/pages/about-us/customer-5.jpg' },
        { imgURL: 'public/images/pages/about-us/customer-3.jpg' },
        { imgURL: 'public/images/pages/about-us/customer-6.png' },
      ],

      // == Carousels options ==
      customersOptions: {
        slidesPerView: 4,
        spaceBetween: 26,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          992: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          576: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  }
}
</script>
