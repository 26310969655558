<template lang="html">
  <div id="company-page">

    <section class="basic-info-section">
      <div class="bg-image"></div>

      <div class="container">
        <div class="row">
          <div class="offset-md-5 col-md-7 offset-lg-6 col-lg-6 col-info">
            <h5 class="mb-2 title">¿Quiénes somos?</h5>

            <p class="p-lg">
              Somos una empresa sólida dedicada a la fabricación de empaque flexible impreso que cuenta con cimientos basados en nuestros valores de operación y en la seguridad de los procesos que llevamos a cabo, ya que gracias a estos hemos mantenido un crecimiento e innovación constante de nuestros productos. Más de 5 años nos respaldan.
            </p>
          </div>

          <!-- <div class="col-lg-6"></div> -->
        </div>
      </div>
    </section>

    <section class="container extra-info-section">
      <div class="row row-info">
        <div class="col-10 col-sm-8 col-md-5 col-lg-4 col-image">
          <div class="placed-backg circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image-2.jpg)' }">
            <img src="public/images/shared/empty-1.png">
          </div>
        </div>

        <div class="col-md-7 col-lg-8 col-text">
          <h2 class="title">MISIÓN</h2>
          <p>
            Estamos comprometidos a producir empaques flexibles de alta calidad, a través de procesos de superación permanente, priorizando el bienestar de nuestro personal y la mayor satisfacción de nuestros clientes.
          </p>
        </div>
      </div>

      <div class="row mt-4 row-info">
        <div class="col-md-7 col-lg-8 text-right order-1 order-md-0 col-text">
          <h2 class="title">VISIÓN</h2>
          <p>
            Ser empresa líder en el ramo de los empaques flexibles para el campo, a través del desarrollo tecnológico, innovación y constante capacitación de nuestro personal para mantenernos siempre a la vanguardia.
          </p>
        </div>

        <div class="col-10 col-sm-8 col-md-5 col-lg-4 order-0 order-md-1 col-image">
          <div class="placed-backg circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image-3.jpg)' }">
            <img src="public/images/shared/empty-1.png">
          </div>
        </div>
      </div>
    </section>

    <CustomersComponent></CustomersComponent>

    <section class="certifications-section">
      <div class="container ">
        <h2 class="title">Certificaciones</h2>

        <div class="row justify-content-center">
          <div class="col-sm-6 col-lg-4 col-image">
            <img src="public/images/pages/about-us/c-1.png" alt="">
          </div>

          <div class="col-sm-6 col-lg-4 col-image">
            <img src="public/images/pages/about-us/c-2.png" alt="">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import CustomersComponent from './customers-component.vue';
export default {
  components: {
    CustomersComponent
  },

  data() {
    return {

    }
  }
}
</script>
